import dayjs from 'dayjs';
import {
  AcceptLoanControllerService,
  AcceptLoanRequest,
  BankAccountControllerService,
  BankAccountRequest,
  BenefitControllerService,
  BenefitReadControllerService,
  BenefitRequest,
  BenefitResponse,
  CalculateSimulationCVGResponse,
  CollectionTicketControllerService,
  CollectionTicketRequest,
  ColletcionTicketGenerateResponse,
  ContractOriginationControllerService,
  ContractOriginationInstallment,
  ContractOriginationResponse,
  CreditOfferRequest,
  CustomerAllowListReadControllerService,
  CustomerAllowListResponse,
  CustomerDocumentControllerService,
  CustomerDocumentRequest,
  CustomerDocumentResponse,
  CustomerLoanControllerService,
  CustomerLoanRequest,
  DomainReadControllerService,
  ListColletcionTicketAntecipationCalcResponse,
  OpenAPI,
  ProposalLoanControllerService,
  ProposalLoanReadControllerService,
  ProposalLoanResponse,
  ResultQuoteControllerService,
  ResultQuoteCPRequest,
  ResultQuoteCVGRequest,
  ResultQuoteResponse,
  SimulationQuoteControllerService,
  SimulationQuoteReadControllerService,
  SimulationQuoteRequest,
  SimulationQuoteResponse,
  UnicoCheckScoreReponse,
  UnicoControllerService,
  UnicoReponse,
  VehicleControllerService,
  VehicleInspectionControllerService,
  VehicleInspectionReadControllerService,
  VehicleInspectionResponse,
  VehicleReadControllerService,
  VehicleRequest,
  VehicleResponse,
} from './generated/personal-loans';

import store from '../store/store';
import { Domain, LoanTypeEnum, SimulationStatusEnum } from '../models';
import {
  capitalizeEveryFirstLetter,
  formatDate,
  getAuthCookies,
  getInfoFromCodebar,
  getNextWorkingDay,
  sortArray,
} from '../utils';
import {
  Boleto,
  City,
  CreditOfferCPGatewayResponse,
  FindOpenedSimulationsResponse,
  ZipCodeAdress,
} from './models/personal-loans';
import { CreditOfferCVGGatewayResponse } from './models/personal-loans/CreditOfferCVGGatewayResponse';
import CustomAPI from './CustomAPI';
import { handleAxiosException } from './error';

const {
  getBanksUsingGet,
  getBrandsUsingPost,
  getModelsUsingPost,
  getVersionsUsingPost,
  getByZipCodeUsingGet,
  getFuelTypesUsingGet,
  getProfessionsUsingGet,
  getCivilStatesUsingGet,
  getNationalitiesUsingGet,
  getProfessionalTypesUsingGet,
  getCitiesByFederativeUnitUsingGet,
} = DomainReadControllerService;
const { createQuoteUsingPost, updateQuoteUsingPut, creditOfferCpUsingPost, creditOfferCvgUsingPost } =
  SimulationQuoteControllerService;
const { saveVehicleUsingPost, updateVehicleUsingPut } = VehicleControllerService;
const { simulationQuoteCpUsingPost, simulationQuoteCvgUsingPost } = ResultQuoteControllerService;
const { saveDocumentUsingPost, updateDocumentUsingPut } = CustomerDocumentControllerService;
const { updateUsingPut4, updateUsingPatch } = CustomerLoanControllerService;
const { createUnicoMessageUsingPost, checkMessageScoreUsingPost } = UnicoControllerService;
const { createScheduleUsingPost } = VehicleInspectionControllerService;
const { getVehicleInspectionUsingGet } = VehicleInspectionReadControllerService;
const { findByCustomerDocumentUsingGet } = CustomerAllowListReadControllerService;
const { findAllByCustomerAndStatusUsingGet, findByCustomerWithStatusUsingGet, findAllByCustomerUuidUsingGet } =
  SimulationQuoteReadControllerService;
const { proposalLoanUsingPost } = ProposalLoanControllerService;
const { colletcionTicketGenerateUsingPost, calcFeesMoraInstalmmentUsingPost, anticipationCalcUsingPost } =
  CollectionTicketControllerService;
const { contractOriginationUsingPost } = ContractOriginationControllerService;
const { saveUsingPost } = AcceptLoanControllerService;
const { getAmountBenefitUsingGet } = BenefitReadControllerService;
const { benefitUsingPost } = BenefitControllerService;
const { getVehicleByCustomerIdUsingGet } = VehicleReadControllerService;
const { createBankAccountUsingPost } = BankAccountControllerService;
const { proposalLoanUsingGet } = ProposalLoanReadControllerService;

OpenAPI.BASE = process.env.REACT_APP_API_BASE_URL as string;
OpenAPI.HEADERS = getAuthCookies();
const baseBodyVehicle = { category: 'leves', zeroKilometer: false };
const { customerId = '' } = store?.getState()?.user;

const getProduct = (): 'CP' | 'CVG' => {
  const product =
    store?.getState()?.personalCredit?.currentQuote?.loan?.loanType?.toUpperCase() ||
    store?.getState()?.personalCredit?.loan?.loanType?.toUpperCase();
  if (product === LoanTypeEnum.CP) return 'CP';
  if (product === LoanTypeEnum.CVG) return 'CVG';
  throw Error('Tipo de produto não determinado');
};

const getQuoteId = () => {
  return store?.getState()?.personalCredit?.currentQuote?.quoteId || store?.getState()?.personalCredit?.loan?.quote?.id || '';
};

export const getVehicleBrands = async (modelYear: number, licensingFederativeUnit: string): Promise<Domain[]> => {
  const brands: any[] = await getBrandsUsingPost(LoanTypeEnum.CVG.toLowerCase(), {
    ...baseBodyVehicle,
    modelYear,
    licensingFederativeUnit,
  });
  return brands.map(value => {
    const domainList: Domain = { id: value.descricaoMarcaVeiculo, name: value.descricaoMarcaVeiculo };
    return domainList;
  });
};

export const getVehicleModelsByBrand = async (
  brand: string,
  modelYear: number,
  licensingFederativeUnit: string,
): Promise<Domain[]> => {
  const brands: any[] = await getModelsUsingPost(LoanTypeEnum.CVG.toLowerCase(), {
    ...baseBodyVehicle,
    brand,
    modelYear,
    licensingFederativeUnit,
  });
  return brands.map(value => {
    const domainList: Domain = {
      id: `${value.descricaoModeloVeiculoParte1}`,
      name: `${value.descricaoModeloVeiculoParte1} ${value.descricaoModeloVeiculoParte2}`,
    };
    return domainList;
  });
};

export const getVehicleVersionByModel = async (
  model: Domain,
  brand: Domain,
  modelYear: number,
  licensingFederativeUnit: string,
): Promise<Domain[]> => {
  const versions: { descricaoVersaoVeiculo: string; quantidadeCilindradasVeiculo: string }[] = await getVersionsUsingPost(
    LoanTypeEnum.CVG.toLowerCase(),
    {
      ...baseBodyVehicle,
      brand: brand.id,
      model: model.id,
      modelComplement: model.name?.replace(`${model.id} `, ''),
      excludedArmored: true,
      modelYear,
      licensingFederativeUnit,
    },
  );
  return versions.map(value => {
    let versionDescriptionFormated: string;
    try {
      versionDescriptionFormated = value.descricaoVersaoVeiculo.replace(model?.name as string, '').trim();
    } catch (e) {
      versionDescriptionFormated = value.descricaoVersaoVeiculo;
    }
    const domainList: Domain = { id: value.descricaoVersaoVeiculo, name: versionDescriptionFormated };
    return domainList;
  });
};

export const createQuote = async (): Promise<SimulationQuoteResponse> => {
  return createQuoteUsingPost(getProduct().toLowerCase(), '', customerId, '*');
};

export const updateQuote = async (body: SimulationQuoteRequest): Promise<SimulationQuoteResponse> => {
  return updateQuoteUsingPut(body, getQuoteId(), customerId, '*');
};

export const createOrUpdateVehicle = async (body: VehicleRequest, uuid?: string): Promise<VehicleResponse> => {
  if (uuid) {
    return updateVehicleUsingPut(body, getQuoteId(), uuid, customerId, '*');
  }
  return saveVehicleUsingPost(body, getQuoteId(), customerId, '*');
};

export const updateCustomer = async (body: CustomerLoanRequest): Promise<any> => {
  return updateUsingPut4(getQuoteId(), body, customerId, '*');
};

export const updateCustomerPatch = async (body: CustomerLoanRequest): Promise<any> => {
  return updateUsingPatch(getQuoteId(), body, customerId, '*');
};

export const createSimulationCP = async (
  countInstallment: string,
  valueLoan: number,
  dueDateFirstInstallment: string,
  coeficiente?: string,
): Promise<ResultQuoteResponse> => {
  const body: ResultQuoteCPRequest = {
    countInstallment,
    dueDateFirstInstallment,
    valueLoan,
    coeficiente,
    age: '',
    document: '',
    simulationCodeId: '',
  };
  return simulationQuoteCpUsingPost(body, '*');
};

export const createSimulationCVG = async (
  countInstallment: string,
  valueLoan: number,
  dueDateFirstInstallment: string,
): Promise<ResultQuoteResponse> => {
  const body: ResultQuoteCVGRequest = {
    countInstallment,
    dueDateFirstInstallment,
    valueLoan: `${valueLoan}`,
    brand: '',
    categoryDescription: '',
    modelYear: '',
    number: '',
  };
  return simulationQuoteCvgUsingPost(body, '*');
};

export const creditOfferCP = async (body?: CreditOfferRequest): Promise<CreditOfferCPGatewayResponse> => {
  return (await creditOfferCpUsingPost(body || { number: '' }, '*')) as CreditOfferCPGatewayResponse;
};

export const findOpenedSimulations = async (): Promise<FindOpenedSimulationsResponse> => {
  return (await findAllByCustomerAndStatusUsingGet('OPENED', customerId, '*')) as unknown as FindOpenedSimulationsResponse;
};

export const creditOfferCVG = async (body?: CreditOfferRequest): Promise<CreditOfferCVGGatewayResponse> => {
  return (await creditOfferCvgUsingPost(body || { number: '' }, '*')) as CreditOfferCVGGatewayResponse;
};

export const saveCustomerDocument = async (body: CustomerDocumentRequest, uuid?: string): Promise<CustomerDocumentResponse> => {
  const id = store?.getState()?.personalCredit?.currentQuote?.customerLoan?.id;

  if (!id) throw new Error('Não tem CustomerLoan salvo');

  if (uuid) {
    return updateDocumentUsingPut(id, body, customerId);
  }
  return saveDocumentUsingPost(id, body, customerId);
};

export const getDomainBanks = async (): Promise<Domain[]> => {
  const response: any[] = await getBanksUsingGet('');
  const bankResponse = response.map(
    ({ numero, nome }): Domain => ({
      id: numero,
      name: `${numero?.padStart(3, '0')} - ${capitalizeEveryFirstLetter(nome, 3)}`,
    }),
  );

  return sortArray(bankResponse, 'name', 'ASC');
};

export const createBankAccount = async (body: BankAccountRequest): Promise<Domain[]> => {
  return createBankAccountUsingPost(body, getQuoteId(), customerId, '*');
};

export const getAdressByZipCode = async (zipcode: string): Promise<ZipCodeAdress[]> => {
  return (await getByZipCodeUsingGet('cvg', zipcode)) as ZipCodeAdress[];
};

export const getDomainCitiesByFederativeUnit = async (federativeUnitInitial: string): Promise<Domain[]> => {
  const response = (await getCitiesByFederativeUnitUsingGet(federativeUnitInitial, 'cvg')) as City[];
  return response
    .filter(({ ativo }) => ativo)
    .map(({ codigo, nome }) => ({ id: String(codigo), name: capitalizeEveryFirstLetter(nome, 2) }));
};

export const getDomainCivilStates = async (): Promise<Domain[]> => {
  const response: any[] = await getCivilStatesUsingGet('cvg');
  return response.map(({ id, partnerCode, description }) => ({
    id:
      id ??
      partnerCode ??
      String(description)
        .toUpperCase()
        .replace(/(\s|\s+)/, '_'),
    name: capitalizeEveryFirstLetter(description),
  }));
};

export const getDomainFuelTypes = async (): Promise<Domain[]> => {
  const response: any[] = await getFuelTypesUsingGet('cvg');
  return response
    .filter(({ ativo }) => !!ativo)
    .map(
      ({ codigo, descricao }): Domain => ({
        id: codigo,
        name: descricao,
      }),
    );
};

export const getDomainNationalities = async (): Promise<Domain[]> => {
  const response: any[] = await getNationalitiesUsingGet('cvg');
  return response.map(
    ({ description }): Domain => ({
      id: String(description)
        .toUpperCase()
        .replace(/(\s|\s+)/, '_'),
      name: capitalizeEveryFirstLetter(description),
    }),
  );
};

export const getDomainProfessionalTypes = async (): Promise<Domain[]> => {
  const response: any[] = await getProfessionalTypesUsingGet('cvg');
  return response.map(
    ({ partnerCode, description }): Domain => ({
      id: partnerCode,
      name: capitalizeEveryFirstLetter(description),
    }),
  );
};

export const getDomainProfessions = async (): Promise<Domain[]> => {
  const response: any[] = await getProfessionsUsingGet('cvg');
  return response.map(
    ({ partnerCode, description }): Domain => ({
      id: partnerCode,
      name: capitalizeEveryFirstLetter(description, 3),
    }),
  );
};

export const createUnicoPartnerSession = async (product = 'CP'): Promise<string> => {
  const response: UnicoReponse = await createUnicoMessageUsingPost(product, getQuoteId(), {}, customerId);
  if (!response.url) throw Error('Falha no serviço de documentos');
  return response.url;
};

export const createDekraParterSession = async (): Promise<VehicleInspectionResponse> => {
  return createScheduleUsingPost(getQuoteId(), customerId);
};

export const getDekraParterSession = async (quoteId?: string): Promise<VehicleInspectionResponse> => {
  return getVehicleInspectionUsingGet(quoteId || '');
};

export const createLoanProposal = async (): Promise<ProposalLoanResponse> => {
  return proposalLoanUsingPost(getProduct(), getQuoteId(), customerId, '*');
};

export const createAcceptLoan = async (resquest?: AcceptLoanRequest): Promise<ProposalLoanResponse> => {
  const body = {
    browser: 'Chrome',
    eventDetail: 'accept-loan',
    ip: '34.94.33.236',
    latitude: 'UNKNOW',
    loginAbasteceai: 'UNKNOW',
    longitude: 'UNKNOW',
    macAddress: '00:00:5e:00:53:af',
    operatingSystem: 'android',
    port: '22',
    timeZone: 'gmt-3',
    typeAccept: '2',
    ...resquest,
  };
  return saveUsingPost(getProduct(), body, getQuoteId(), customerId, '*');
};

export const getSimulationByProduct = async (product, status = SimulationStatusEnum.OPENED): Promise<SimulationQuoteResponse> => {
  return findByCustomerWithStatusUsingGet(product || getProduct(), status, customerId, '*');
};

export const getContractDetails = async (simulation = '', loanType: LoanTypeEnum): Promise<ContractOriginationResponse> => {
  return contractOriginationUsingPost(loanType, { simulation }, customerId, '*');
};

export const getBenefit = async (): Promise<Array<BenefitResponse>> => {
  return getAmountBenefitUsingGet(getQuoteId(), '*');
};

export const createBenefit = async (benefit: string): Promise<Array<BenefitResponse>> => {
  const body: BenefitRequest = {
    simulationUuid: getQuoteId(),
    partner: 'BV',
    product: getProduct(),
    benefitType:
      BenefitRequest.benefitType.CASHBACK.toUpperCase() === benefit.toUpperCase()
        ? BenefitRequest.benefitType.CASHBACK
        : BenefitRequest.benefitType.KMV,
  };
  return benefitUsingPost(body, customerId, '*');
};

const baseBodyBoleto: CollectionTicketRequest = {
  channelCode: 1,
  receiptCode: 11,
  billingInstrumentCode: '10',
};

export const getAnticipationSimulation = async (
  installments: ContractOriginationInstallment[],
): Promise<ListColletcionTicketAntecipationCalcResponse> => {
  const contractCode = store?.getState()?.personalCredit?.loan?.contractNumber?.toString();
  const body: CollectionTicketRequest = {
    ...baseBodyBoleto,
    installments: installments.map(({ installment }) => ({ installment })),
    dueDate: getNextWorkingDay().format('YYYY-MM-DD'),
    contractCode,
  };
  return anticipationCalcUsingPost(getProduct(), body, customerId, '*');
};

const mountBoletoInfo = (response: ColletcionTicketGenerateResponse, installments: ContractOriginationInstallment[]) => {
  if (!response?.digitableLine) {
    return undefined;
  }
  const { dueDate: dueDateFromCodebar, value: valueFromCodeBar } = getInfoFromCodebar(response.digitableLine);
  return {
    dueDate: dueDateFromCodebar || '',
    digitableLine: response.digitableLine || '',
    installments,
    value: valueFromCodeBar || 0,
  };
};

export const getBoleto = async (
  installments: ContractOriginationInstallment[] | undefined,
  options?: { value?: number; antecipation?: boolean },
): Promise<Boleto | undefined> => {
  if (!installments || installments.length === 0) throw Error('Parcela não informada');
  const contractCode = store?.getState()?.personalCredit?.loan?.contractNumber?.toString();
  const overdue = installments.some(({ qtdLateDay }) => qtdLateDay && qtdLateDay > 0);
  const dueDate =
    options?.antecipation || overdue || installments.length > 1
      ? getNextWorkingDay().format('YYYY-MM-DD')
      : installments[0].workDayNextExpiration || '';
  const body: CollectionTicketRequest = {
    ...baseBodyBoleto,
    installments: installments.map(({ installment }) => ({
      installment,
    })),
    contractCode,
    dueDate,
    value: options?.value,
  };
  if (overdue) {
    return calcFeesMoraInstalmmentUsingPost(getProduct(), body, customerId, '*')
      .then((response: ColletcionTicketGenerateResponse) => mountBoletoInfo(response, installments))
      .catch(error => Promise.reject(error));
  }
  return colletcionTicketGenerateUsingPost(getProduct(), body, customerId, '*')
    .then((response: ColletcionTicketGenerateResponse) => mountBoletoInfo(response, installments))
    .catch(error => Promise.reject(error));
};

export const getVehicleById = async (uuid = ''): Promise<VehicleResponse> => {
  return getVehicleByCustomerIdUsingGet(uuid);
};
export const customerIsAllowed = async (): Promise<boolean> => {
  const response: CustomerAllowListResponse = await findByCustomerDocumentUsingGet('');
  return response.status === 1;
};

export const getLimitSimulationCVG = async (valueLoan = 0): Promise<CalculateSimulationCVGResponse> => {
  const date = formatDate.toDateOnlyISO(dayjs().add(30, 'day').toDate());
  const body = { valueLoan };
  return CustomAPI.post<CalculateSimulationCVGResponse>(`/api/personal_loans/simulation/product/CVG/${date}`, body)
    .then(response => Promise.resolve(response.data))
    .catch(reason => Promise.reject(handleAxiosException(reason.response)));
};

export const checkUnico = async (): Promise<UnicoCheckScoreReponse> => {
  return checkMessageScoreUsingPost(getProduct(), getQuoteId(), { jwtToken: '' }, customerId, '*');
};

export const getAllSimulationsByUser = async (): Promise<Array<SimulationQuoteResponse>> => {
  return findAllByCustomerUuidUsingGet(customerId, '*');
};

export const getProposalLoan = async (): Promise<ProposalLoanResponse> => {
  const product = getProduct();
  const simulationQuoteId = getQuoteId();
  return proposalLoanUsingGet(product, simulationQuoteId, customerId, '*');
};
